/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';

const slider = {};

slider.init = () => {
	slider.getSlides();
	$('[rel="slider-for"]').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		initialSlide: 1,
		adaptiveHeight: true,
		asNavFor: '[rel="slider-nav"]',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					arrows: false,
				},
			},
		],
	});
	$('[rel="slider-nav"]').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '[rel="slider-for"]',
		arrows: false,
		dots: true,
		centerMode: true,
		centerPadding: '0',
		focusOnSelect: true,
		infinite: true,
		initialSlide: 1,
	});
};

slider.getSlides = () => {
	const sliderFor = document.querySelector('[rel="slider-for"]');
	const sliderForMessages = slider.getMessages();
	for (const i in sliderForMessages) {
		const li = document.createElement('li');
		li.innerHTML = sliderForMessages[i];
		sliderFor.appendChild(li);
	}
};

slider.getMessages = () => {
	const sliderNav = document.querySelector('[rel="slider-nav"]');
	const testimonials = Array.from(sliderNav.querySelectorAll('.testimonial'));
	const messages = [];
	testimonials.map((testimonial) => {
		messages.push(
			`<p class="testimonial-message">${testimonial.getAttribute(
				'data-message'
			)}</p>`
		);
	});
	return messages;
};

slider.init();
